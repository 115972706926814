export const TeamData = {
  members: [
    {
      key: "1",
      name: "Gaurish Sehgal",
      position: "Chairperson ACM",
      linkedInURL: "https://www.linkedin.com/in/gaurish-sehgal-901746249/",
      githubURL: "",
      instaURL: "",
      twitterURL: "",
      displayPicSrc: require("../assets/display-pics/Gaurish.png")
    },
    {
      key: "2",
      name: "Satvik Phour",
      position: "Vice Chairperson ACM",
      linkedInURL: "https://www.linkedin.com/in/satvik-phour-6a97a62b6/",
      githubURL: "https://github.com/SatvikPhour",
      twitterURL: "https://x.com/phour_satvik",
      instaURL:"https://www.instagram.com/_satvik_16/",
      displayPicSrc: require("../assets/display-pics/Satvik.jpg")
    },
    {
      key: "3",
      name: "Sanskar kannaujia",
      position: "Chairperson ACM-W",
      linkedInURL: "",
      githubURL: "https://github.com/not-sans",
      instaURL:"https://www.instagram.com/_not_sanskari_/",
      twitterURL: "",
      displayPicSrc: require("../assets/display-pics/Sanskar.webp")
     
    },
    {
      key: "4",
      name: "Harsh Raj",
      position: "Vice Chairperson ACM-W",
      linkedInURL: "https://www.linkedin.com/in/harsh-raj-165444290/",
      githubURL: "https://github.com/ITZharsh05",
      instaURL: "https://www.instagram.com/dusky_harrssh/",
      twitterURL: "https://x.com/rajh17787",
      displayPicSrc: require("../assets/display-pics/HarshRaj.JPG")
    },
 
    {
      key: "5",
      name: "Harshit Bareja",
      position: "WebMaster ACM",
      linkedInURL: "https://www.linkedin.com/in/harshit-bareja-359a36292/",
      githubURL: "https://github.com/Harshit1607",
      instaURL: "https://www.instagram.com/harshit_bareja07/",
      twitterURL: "https://x.com/bareja_harshit",
      displayPicSrc: require("../assets/display-pics/Harshit.JPG")
    },
    {
      key: "6",
      name: "Dev",
      position: "General Secretary ACM",
      linkedInURL: "https://www.linkedin.com/in/dev-singh-11aa812a8/",
      githubURL: "",
      twitterURL: "",
      instaURL:"https://www.instagram.com/devsinghh30/",
      displayPicSrc: require("../assets/display-pics/Dev.jpg")
    },
    // {
    //   key: "7",
    //   name: "Shwetank Chaudhary",
    //   position: "Joint Secretary ACM",
    //   linkedInURL: "https://www.linkedin.com/in/shwetank-chaudhary-b03ab520a/",
    //   githubURL: "https://github.com/hacker-kun",
    //   twitterURL: "",
    //   instaURL:"https://www.instagram.com/i.m.in_evi_t_able/",
    //   displayPicSrc: require("../assets/display-pics/Shewtank.jpg")
    // },
      
    // {
    //   key: "8",
    //   name: "Saurav Sakkarwal",
    //   position: "Joint Secretary ACM-W",
    //   linkedInURL: "https://www.linkedin.com/in/saurav-sakkarwal-185890217/",
    //   githubURL: "",
    //   twitterURL: "",
    //   instaURL: "https://www.instagram.com/saurav.0073/",
    //   displayPicSrc: require("../assets/display-pics/Saurav.jpg")
    // },

    {
      key: "9",
      name: "Astha Kumari ",
      position: "Treasurer ACM",
      linkedInURL: "https://www.linkedin.com/in/astha-kumari-a4b45925a/",
      githubURL: "https://github.com/astha1503",
      // twitterURL: "",
      instaURL:"https://www.instagram.com/astha_1503/",
      displayPicSrc: require("../assets/display-pics/Aastha.JPG")
    },

    {
      key: "10",
      name: "Harshita",
      position: "Membership Chair ACM",
      linkedInURL: "https://www.linkedin.com/in/harshita-suneja-7820a0293/",
      githubURL: "",
      twitterURL: "",
      instaURL: "https://www.instagram.com/harshieyss_/",
      displayPicSrc: require("../assets/display-pics/HarshitaSuneja.jpg")
    }
  ],
  mentors: [
    
    {
      key: "12",
      name: "Prof. Sarita Yadav",
      displayPicSrc: require("../assets/display-pics/saritayadav.jpg")
    },
    {
      key: "13",
      name: "Vaibhav Choudhary",
      displayPicSrc: require("../assets/display-pics/Vaibhav.jpeg")
    }
    
  ]
};


export default TeamData 